<template>
  <base-card>
      <p>Select your quarter</p>
    <label for="Q1">Q1</label>
    <input
      type="radio"
      value="Q1"
      id="Q1"
      name="qtr"
      v-model="qtr"
      @change="setQtr"
    />
    <label for="Q2">Q2</label>
    <input
      type="radio"
      value="Q2"
      id="Q2"
      name="qtr"
      v-model="qtr"
      @change="setQtr"
    />
    <label for="Q3">Q3</label>
    <input
      type="radio"
      value="Q3"
      id="Q3"
      name="qtr"
      v-model="qtr"
      @change="setQtr"
    />
    <label for="Q4">Q4</label>
    <input
      type="radio"
      value="Q4"
      id="Q4"
      name="qtr"
      v-model="qtr"
      @change="setQtr"
    />
    <p>Authenticate with one of the following auth providers</p>
    <button v-google-signin-button="clientId" class="google-signin-button">
      Google
    </button>
    <br /><br />
    <h3>Or</h3>
    <br /><br />
    <textarea
      id="token"
      rows="5"
      cols="80"
      v-model="token"
      placeholder="Please paste your Google authentication token here"
    ></textarea
    ><br />
    <base-button type="button" @click.native="onSet">Set</base-button>
    <br /><br />
    <textarea
      id="tdatoken"
      rows="5"
      cols="80"
      type="password"
      v-model="tdatoken"
      placeholder="Please paste your TDA authentication token here. This is only needed to pull real time quotes and option chains."
    ></textarea
    ><br />
    <input
      type="password"
      v-model="tdacct"
      placeholder="Please enter your TD Ameritrade acct here"
      class="text"
    />
    <br /><br />
    <base-button type="button" @click.native="onSetTDA">Set</base-button>
    <br /><br />

    <label for="orderLookBack">OrderLookBack: </label>
    <input
      id="orderLookBack"
      type="number"
      v-model="orderLookBack"
      class="number"
      @change="setOrderLookBack"
    /><br />


  </base-card>
</template>

<script>
export default {
  data: () => ({
    clientId:
      "140027062918-iefqmkj84frakep3hg2dlkmn8mct9lpt.apps.googleusercontent.com",
    token: "",
    tdatoken: "",
    qtr: null,
    tdacct: null,
    orderLookBack: 1
  }),
  methods: {
    OnGoogleAuthSuccess(idToken) {
      console.log(idToken);
      this.$store.dispatch("postLogin", { token: idToken });
      this.$router.push("/pt/AMZN");
    },
    OnGoogleAuthFail(error) {
      console.log(error);
    },
    onSet() {
      this.$store.dispatch("postLogin", { token: this.token });
      this.$router.push("/option");
    },
    onSetTDA() {
      this.$store.dispatch("postTDA", {
        tdatoken: this.tdatoken,
        tdacct: this.tdacct,
      });
      alert("TDA details have been set!");
      this.$router.push("/pt/AMZN");
    },
    async setOrderLookBack() {
      this.$store.dispatch("misc/setOrderLookBack", { orderLookBack: this.orderLookBack });
    },
    async setQtr() {
      this.$store.dispatch("misc/setQtr", { qtr: this.qtr });
      if (this.$store.getters["pnl/hasPnLs"]) {
        try {
          await this.$store.dispatch("pnl/setPnLs", {
            qtr: this.qtr,
          });
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
      if (this.$store.getters["prospect/hasProspects"]) {
        try {
          await this.$store.dispatch("prospect/setProspects", {
            qtr: this.qtr,
          });
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
    },
  },
};
</script>

<style scoped>
.google-signin-button {
  color: white;
  background-color: #05420d;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

p {
  font-weight: bold;
}
label {
  margin-left: 20px;
}
input.text {
  width: 400px;
  height: 24px;
  padding: 0px 0px;
}
</style>